import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import "./App.css";
import "./pages/shared.scss";
import { AuthProvider } from "./api/providers/auth";
import Routes from "./pages/Routes";

export default function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024)",
  });
  if (isDesktopOrLaptop) {
    return <p className="Desktop">Please Use A Mobile Or Tablet</p>;
  } else {
    return (
      <Suspense fallback={<p className="Desktop">Loading...</p>}>
        <Router>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </Router>
      </Suspense>
    );
  }
}
