import { apiClient, setApiKey } from "./client";

// List information about associated housing companies of a tenant
export const getAssociatedCompanyDetails = () =>
  apiClient.get(`/company/info`).then((res) => res.data);

// List tenant master data information of one tenant based on usage periods
export const getMasterData = (id) =>
  apiClient.get(`/master_data/${id || ""}`).then((res) => res.data.data);

// List all relevant meters or show data of one meter of tenant
export const getMeterData = (id) =>
  apiClient.get(`/meters/${id || ""}`).then((res) => res.data.data);

// List background information according to consumption, heating, and billing
export const getGeneralInfo = () =>
  apiClient.get(`/general_info`).then((res) => res.data.data);

// Login
export const verifyLogin = async (token) => {
  setApiKey(token);
  const userData = await getMasterData().catch((e) => {
    console.error(e);
    return null;
  });
  if (!userData) setApiKey(null);
  return userData;
};
