import React from "react";

import { Redirect, Route, Switch } from "react-router";
import { useAuth } from "../api/providers/auth";

const BillingHistory = React.lazy(() => import("./Billing History"));
const Main = React.lazy(() => import("./Main"));

const CheckAuth = React.lazy(() => import("./Splash Screen"));
const First = React.lazy(() => import("./auth-method"));
const Cookies = React.lazy(() => import("./Cookies"));
const Login = React.lazy(() => import("./Login"));
const Home = React.lazy(() => import("./Home"));
const Meter = React.lazy(() => import("./Meter Listing"));
const Detail = React.lazy(() => import("./Detail"));
const Scanner = React.lazy(() => import("./Qr Scanner"));
const ComparisonOverview = React.lazy(() => import("./Comparision Overview"));
const Comparision = React.lazy(() => import("./Comparision"));
const AdditionalInformation = React.lazy(() => import("./Additional Information"));

export default () => {
  const { user } = useAuth();

  return user ? (
    <Switch>
      <Route path="/" exact={true}>
        <Home />
      </Route>
      <Route path="/Comparision">
        <Comparision />
      </Route>
      <Route path="/ComparisonOverview">
        <ComparisonOverview />
      </Route>
      <Route path="/ComparisonOverviewAverage">
        <ComparisonOverview averageState/>
      </Route>
      <Route path="/Meter/:serial">
        <Detail />
      </Route>
      <Route path="/Meter">
        {/*<Meter />*/}
      </Route>
      <Route path="/BillingHistory">
        {/*<BillingHistory />*/}
      </Route>
      <Route path="/AdditionalInformation">
        <AdditionalInformation />
      </Route>
      <Route path="/Cookies">
        <Cookies />
      </Route>
      <Redirect to="/Cookies" />
    </Switch>
  ) : (
    <Switch>
      <Route path="/" exact={true}>
        {/*<Main />*/}
        <Login />
      </Route>
      <Route path="/auth-method">
        <First />
      </Route>
      <Route path="/Scanner">
        <Scanner />
      </Route>
      <Route path="/Login/:code">
        <Login />
      </Route>
      <Route path="/Login">
        <Login />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};
