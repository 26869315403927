import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import { verifyLogin } from "../services/auth";
import { setApiKey } from "../services/client";
import { useTranslation } from "react-i18next";

const authContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const login = useCallback(async (code) => {
    code = code.split("/").slice(-1)[0].toUpperCase();
    setLoading(true);
    setError(false);
    const user = await verifyLogin(code);
    setLoading(false);
    setUser(user);
    if (user) history.replace("/Cookies");
    if (!user) setError(true);
  }, []);

  const logout = useCallback(async () => {
    setApiKey(null);
    setUser(null);
    setError(false);
    setLoading(false);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token)
      verifyLogin(token).then((user) => {
        setUser(user);
      });
    else setUser(null);
  }, []);

  return user === undefined ? (
    <div className="Splash">
      <h1 className="heading">
        {/*HP <b>Consumption</b>{" "}*/}
        {t('hp-consumption-info')}
      </h1>
    </div>
  ) : (
    <authContext.Provider
      value={{
        loading,
        error,
        setError,
        login,
        logout,
        user,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
export const useAuth = () => useContext(authContext);
